import React from "react";
import "./styles.css";
import Nav from "./navbar";
import Row from "./row";
import * as data from "./data"; //database

export default function App() {
  return (
    <div className="App">
      {Nav()}
      <h2
        className="display-2"
        style={{
          fontSize: "32px",
          textAlign: "left",
          color: "#f3b00c",
          marginTop: "5px",
          paddingLeft: "15px",
          paddingRight: "25px"
        }}
      >
        Day #21
      </h2>
      {Row(1, data.data[1], data.data[2], data.data[3])}
      {Row(2, data.data[4], data.data[5], data.data[6])}
      {Row(3, data.data[7], data.data[8], data.data[9])}
      {Row(4, data.data[10], data.data[11], data.data[12])}
      {Row(5, data.data[13], data.data[14], data.data[15])}
      {Row(6, data.data[16], data.data[17], data.data[18])}
      {Row(7, data.data[19], data.data[20], data.data[0])}
      {Row(8, data.data[21], data.data[22], data.data[23])}
    </div>
  );
}
