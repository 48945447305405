export let data = [
  '{"name": "Pranav Patil", "place": "Parvati, Pune", "waNumber": "+919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4645023cc8b8a2e68b03a303d396567534ce5388ca5019141e34d1b5cae6d5a1/20210101_175619.jpg", "scribbleSpace": "5\'amers bring a positive change to the World, and happiness to me! I like to have simple friends. Fitness is passion.", "contribution": "Power ideas, thoughts and experiences", "intro": "Happiness coach"}',
  '{"name": "Prakash Patil", "place": "Parvati, Pune", "waNumber": "+919822257065", "email": "ppatil54@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a68bedee2f185ed3537d5cf4baa17266f115c4fcf06fe86f61690211cee4da71/1609503021010351144383837896674.jpg", "scribbleSpace": "Early to bed and early to rise makes woman/man healthy, wealthy and wise.", "contribution": "Don\'t know.", "intro": "Nature lover"}',
  '{"name": "Mugdha Gosavi", "place": "Kothrud Pune", "waNumber": "+919731870408", "email": "mugdha125@gmail.com", "photoLine": "https://api.typeform.com/responses/files/781be65e56bca722f194bd91b80e1328ddaa67048e0a6f4c08fc39eb7cb6eb7b/IMG_20200108_133728790.jpg", "scribbleSpace": "A designer working in education and development sector. Love creating things with hands. Like to keep learning new skills.\\n\\nWorking on adapting healthy habits- eating healthy, staying fit ( mentally and physically)", "contribution": "May be a quick doodling session or sharing something to do with how to be creative.", "intro": "Designer"}',
  '{"name": "Balaji Mankari", "place": "Bangalore", "waNumber": "+918125864262", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e11c0b5428e904caa470a2803be496198b0d034ad3ab7930cc2ac15a8e0d88f9/9950B359_5D2A_4BD4_858D_4D69C88E0576.jpeg", "scribbleSpace": "I am hard core trekker and I love to do road trips", "contribution": "", "intro": "Traveller"}',
  '{"name": "Nikhil Phalak", "place": "Wakad Pune", "waNumber": "+918976898360", "email": "nikhil.phalak@iiml.org", "photoLine": "https://api.typeform.com/responses/files/3df21c96d00f3522f19cff052b364a3f42bc4791993cf7289c5cdf675dcf52bd/20201229_091951.jpg", "scribbleSpace": "Hello, \\nI have been trying to instill the habit of waking up early and exercising since a long time and I have failed multiple times in past. \\nAlso I wanted some uninterrupted time of my busy office schedule which I can dedicate to building up new skills, certifications. \\n\\nI hope interacting with like-minded people in 5am club would help me to build up this habit and stick to it.", "contribution": "", "intro": ""}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Sandeep Bhandari", "place": "Marketyard, Pune", "waNumber": "+919890016111", "email": "srbhandari76@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/79cb1fa9d3befc35787609044be6ceb4f8c305bded8129531446b4fa61e4cfd7/IMG20201120120617.jpg", "scribbleSpace": "Trekking running reading", "contribution": "No", "intro": "Fitness freak"}',
  '{"name": "Vikram Kulkarni", "place": "Magarpatta City, Pune", "waNumber": "+919960612409", "email": "vikramkulkarni9@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bbe5fa908aa93825d2762fb31e6223edfb117c176b93076c51fb881d5a6ec5ea/IMG_20210101_190415.jpg", "scribbleSpace": "", "contribution": "", "intro": ""}',
  '{"name": "Ketan Ruplag", "place": "Wakad-Pune", "waNumber": "+919561648880", "email": "kruplag@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bff327acf4265356e401ca9c4c69b505d9e7a1d10877c9e7986274f1699235b2/IMG_20200105_125031.jpg", "scribbleSpace": "I am an experienced professional in CAD domain and passionate about cricket. My plan is to appear for Project Management certification and look for opportunities in sports management.", "contribution": "Effective ways of Leading a team and qualities you should possess to lead team successfully", "intro": "Nature lover"}',
  '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "+919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
  '{"name": "Shruti Ghurye", "place": "Kandivali Mumbai", "waNumber": "+919137883705", "email": "shrutisghurye@gmail.com", "photoLine": "https://api.typeform.com/responses/files/45f6e428ac91aef1bb634e18d60a7880e7011ff3fa52ec650db70f959366858d/Screenshot_20201225_130556_Photos.jpg", "scribbleSpace": "Entrepreneurship: Own a homestay business and a marketing consultancy in it\'s infancy, Hobbies: Swimming, Singing, Driving, Reading. Expectations and issues: Tendency to get stuck and problems with discipline. Waveriness and inconsistency. Poor social skills. Want to work on all of these.", "contribution": "Knowledge about UPSC exams, Sociology, marketing, Hospitality, Cooking", "intro": "Figuring out who I am. Identify myself as an ambitious, empathetic and resilient person"}',
  '{"name": "Pallavi Dhoot", "place": "Senapati Bapat Marg, Pune", "waNumber": "+919822008007", "email": "pallavidhoot.nature@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f562524e23ca1314e06cf69db8e1daa6423eb810c7330f9255f984df68d901fa/Screenshot_20210104_042823.png", "scribbleSpace": "I am an early riser.But getting up every day exactly at the same time is something I am trying for the first time.  A dedicated mother and wife, a passionate Naturopath and researcher, a sustainable lifestyle explorer, is how I describe myself.", "contribution": "Can share my knowledge, thoughts and experiences", "intro": "An Explorer"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "+919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "Ayush Agrawal", "place": "Bund Garden Road, Pune", "waNumber": "+918879280819", "email": "ayush.agrawal@iiml.org", "photoLine": "https://api.typeform.com/responses/files/11c3b6f088f75e656c299ede4c4cdc78496f86dae43b3ab2a0e3e122b8819b0a/171009_Passport_Pic.jpg", "scribbleSpace": "Strategy tabletop board games and quizzing are two hobbies. Want to build a habit of waking up early to gain an extra 2-3 hours each day, and start the day with an uncluttered mind.", "contribution": "I can conduct a fun 10 minute quiz in one of the sessions :)", "intro": "Serial entrepreneur"}',
  '{"name": "Nilisha Raygandhi", "place": "Parvati, Pune", "waNumber": "+919822254619", "email": "nilishaaa@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4900b00636c7f8afc4e160810ba6faa9e88a07b9756b21c6237f110b3206f0f1/IMG_20201230_170149.jpg", "scribbleSpace": "Dance, Fitness,Sports,Creation, Motivational speaker, Natural decipherer for knowledge of the physical world & the world within !!!\\nI work in variety of fields & continue to add more as i proceed, I naturally have been a Zen person all my life, unaware though untill i stumbled upon the term !!!\\nI literally am a live-in-the-moment-kinda-person, Believer, highly motivated. I look forward to bettering myself with new things & to be in spaces which add to that & where i can add value to it with my presence , naturally & organically.", "contribution": "Fitness - Bodymovement - Dance", "intro": "Concept Creator, Artist,Dance director,Bodymovement/Fitness instructor"}',
  '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "+918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/c226584b7e5d2516a337ebbb3261a5ca5085c9158d0e88a0b4887fceab40a4ef/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional. Married. Quizzing, US Politics and Cooking are my hobbies. Learning to speak Esperanto.", "contribution": "a) The thought process behind setting good quiz questions b) Abour Esperanto and why I am interested in it c) Basics of cooking simple home-made curries d) The US Political System and US Elections", "intro": "Decent Quizzer"}',
  '{"name": "Neelam Bitla", "place": "Guruwar Peth Pune", "waNumber": "+919960840401", "email": "neelam.saka@gmail.com", "photoLine": "https://api.typeform.com/responses/files/905cedb43d351d1f04bdfb886883545d29437d3bf53c6298bc275bf56fc68042/2021_01_01_21_36_53_367.jpg", "scribbleSpace": "I love traveling, I like do my work passionately and I like to spend quality time with my hubby & child", "contribution": "", "intro": "I am professional Accountant"}',
  '{"name": "Sneha Shete", "place": "Shivane,Pune", "waNumber": "+919067631491", "email": "snehashete942@gmail.com", "photoLine": "https://api.typeform.com/responses/files/44f4107bd8c6eb64198eebfb4261123b5b7e88601c160efad06b42f5b188d446/IMG_20170513_145531.jpg", "scribbleSpace": "Music, Dance,Want to explore and learn new ideas ,positive sessions on spiritual knowledge", "contribution": "1 .Morning Gratitude Affirmations \\n2. 3 ways to increase your happiness \\n3.10 Steps to Protect your energy\\n4.Healthy Relationships. \\n4.Your Child\'s behaviour \\n5.Heal and Evolve \\n6.Raise your Vibration\\n7 . Dance/Music", "intro": "Dance Coach , Practicing as Spritual Adviser"}',
  '{"name": "Shweta Dandekar", "place": "Bund Garden Road, Pune", "waNumber": "+918286727655", "email": "shwetadandekar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1f926e0657e91b641b10b751f0560fc3b8c4a857c7824f54750a118acd47d013/IMG_20200906_013718_183.jpg", "scribbleSpace": "", "contribution": "", "intro": ""}',
  '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "+919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f4eaafb5503156aa6a3885a9d91fe9ee1535db7ccb0f0841c9c33d90fc0f3a3d/IMG20200718122438.jpg", "scribbleSpace": "Dance, writing occasionally\\n\\n But can\'t think of anything now", "contribution": "Not sure what I am good at... Anything that takes short time :)", "intro": "A humanist teacher"}',
  '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "+918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
  '{"name": "Raay", "place": "Bengaluru", "waNumber": "+918884100820", "email": "ray.birajdar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4182f7cf7cab500a3306a5797d0866c489e8406949870d08cb497e9468a875ba/20210101_200834.jpg", "scribbleSpace": "Tennis buff, interested in Hindustani Classical Music, ancient civilizations, Religions; fond of dogs", "contribution": "Not an expert on anything", "intro": "Tennis buff, dog lover"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Shuchi Taparia", "place": "Powai Mumbai", "waNumber": "+917738387779", "email": "Shuchi.taparia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9a3985656f5f8f072bde03b17a6cdd0cb6e5cd5a51eb835996ca59b4479b3ce1/794834BD_B233_415A_9382_14A9F3EF6FCD.jpeg", "scribbleSpace": "Playing guitar, dance, chant nam myoho renge kyo, meditation, cycling.", "contribution": "Positive thoughts as of now..", "intro": "Love to explore everything new!"}'
];
